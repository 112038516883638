import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import configData from "../../../appsettings.json";
import { useAuth } from '../../Auth/AuthProvider';
import { searchCarpetParameter } from '../../../js/Constant';
import { getCurrentUserFromJwtToken } from '../../../js/Utils.js';
import { Loading } from '../../Common/Loading';
import { Error } from '../../Common/Error';
import { Gallery } from '../Gallery';
import { M3Pax } from "../../Common/M3Pax";
import { ListSearchEngineCV } from '../SearchEngine/compactView/ListSearchEngineCV';
import { createTourCarpet } from '../../../js/ProductService';
import { ActivityDetailHeader } from './../Activity/components/ActivityDetailHeader';
import { ActivityDetailFavourite } from './../Activity/components/ActivityDetailFavourite';
import { TourDetailCategoryInfo } from './components/TourDetailCategoryInfo';
import { TourDetailDescriptions } from './components/TourDetailDescriptions';
import { ProductCalendar } from '../Common/ProductCalendar';
import { TourDetailInclusionExclusion } from './components/TourDetailInclusionExclusion';
import { TourDetailTouchPoints } from './components/TourDetailTouchPoints';
import { TourRoom } from './components/TourRoom';
import { ProductNavBar } from '../SearchEngine/ProductNavBar';
import { ActivityDetailDescriptions } from '../Activity/components/ActivityDetailDescriptions';


export const TourDetail = () => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const [cultureName] = useState(cookies.get("CultureName"));

    let params = new URLSearchParams(window.location.search);
    let tourId = params.get('aId');
    let onRequest = params.get('onReq');
    let supplier = params.get('supplier') !== "null" ? params.get('supplier') : "";


    let clientFlowId = params.get('cfId');
    let carpetSearchId = params.get('cId');
    let defaultSelectDate = params.get('sDate');
    let { getQuotationInfoSession } = useAuth();

    // carpet
    // per questioni di ottimizzazione genero il carpet direttamente nella pagina
    const [carpetAvailabilityId, setCarpetAvailabilityId] = useState(params.get('caId'));
    const [carpetStatus, setCarpetStatus] = useState(null);
    const [carpetDataStatus, setCarpetDataStatus] = useState(null);
    const [carpetTime, setCarpetTime] = useState(1);
    const [intervalCarpetPing, setIntervalCarpetPing] = useState([]);

    // page
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [isErrorStaticData, setIsErrorStaticData] = useState(false);
    const [tourData, setTourData] = useState(null);
    const [tourDetail, setTourDetail] = useState(null);
    const [showPriceBar, setShowPriceBar] = useState(false);
    const [isLoadingListResult, setIsLoadingListResult] = useState(true);
    const [isErrorListResult, setIsErrorListResult] = useState(false);
    const [quotationInfo, setQuotationInfo] = useState(null);
    const [touchPoints, setTouchPoints] = useState(null);

    // roles
    const [showCost, setShowCost] = useState(false);
    const [enableShowNetPrices, setEnableShowNetPrices] = useState(false);
    const [enableShowPriceBreakDown, setEnableShowPriceBreakDown] = useState(false);
    const [enableCanAddToCart, setEnableCanAddToCart] = useState(false);

    // filtri
    const [totalOptions, setTotalOptions] = useState(0);
    const [onlyAvailable] = useState(true);
    const [isLoadingFilter, setIsLoadingFilter] = useState(false);
    const [filterValues, setFilterValues] = useState(null);
    const [filterSelected, setFilterSelected] = useState({
        numOptionsToView: searchCarpetParameter.tour.detailMaxItem,
        showAllOptions: false,
        onlyAvailable: onlyAvailable,
        selectDate: defaultSelectDate,
        availRoomsReq: []
    });

    const [extrasRoom, setExtrasRoom] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        setIsError(false);
        getStaticData();

        try {

            let jCurrentUser = JSON.parse(localStorage.getItem("CurrentUser"));
            let currentUser = getCurrentUserFromJwtToken(jCurrentUser.token);

            if (currentUser.roles) {
                if (!currentUser.roles.includes("FE_NonVisualizzaNetto"))
                    setEnableShowNetPrices(true);

                if (currentUser.roles.includes("FE_ShowCost"))
                    setShowCost(true);

                if (currentUser.roles.includes("FE_ShowPriceBreakDown"))
                    setEnableShowPriceBreakDown(true);
            }

        } catch (ex) {
        }
    }, []);

    useEffect(() => {

        switch (carpetStatus) {
            case 'Processing':
            case 'Queued':
            case 'Completed':
                {
                    break;
                }
            case 'Error':
                {
                    setIsError(true);
                    setIsLoading(false);
                    break;
                }
        }


    }, [carpetStatus]);

    useEffect(() => {
        switch (carpetDataStatus) {
            case 'Completed':
                {
                    callGetNewData();
                    clearCarpetAllInterval();
                    break;
                }

            case 'NewData':
                {
                    callGetNewData();
                    break;
                }

            case 'Processing':
            case 'NoChanges':
                {

                    let interval = setInterval(() => {
                        getCarpetStatus();
                    }, searchCarpetParameter.tour.carpetPingMs);
                    addInterval(interval);

                    break;
                }
        }

    }, [carpetDataStatus]);

    useEffect(() => {
        //if (!tourData)
        //startDetailSearch();

    }, [tourData]);

    useEffect(() => {
        if (carpetSearchId)
            getCarpetStatus();
    }, [carpetSearchId]);

    useEffect(() => {
        if (getQuotationInfoSession && getQuotationInfoSession.quotationId)
            setQuotationInfo(getQuotationInfoSession);
        else
            setQuotationInfo(null);

    }, [getQuotationInfoSession]);


    const onChangeShowPriceBar = () => {
        setShowPriceBar(!showPriceBar);
    }

    // API carpet
    async function getCarpetStatus() {
        console.log(`Call GetCarpetStatus [CarpetId = ${carpetSearchId}]`);

        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}carpet/getcarpetstatus/${carpetSearchId}`, requestOption);

        if (!fetchedRes.ok) {
            setIsError(true);
        } else {
            const response = await fetchedRes.json();

            let dataStatus = response.dataStatus;
            let status = response.status;
            if (response.success) {
                setCarpetStatus(status);

                if (status !== 'Error') {
                    // messo per richiamare il getCarpetStatus, nel caso riceva piu volte lo status NoChanges.
                    // lo status Processing esiste solo lato FE
                    if (status !== carpetDataStatus)
                        setCarpetDataStatus(dataStatus);
                    else
                        setCarpetDataStatus('Processing');
                }


            } else {
                setIsError(true);
            }

            console.log(`Status ${status}`);
            console.log(`Data Status ${dataStatus}`);
        }

    }

    function addInterval(intervalId) {
        let arr = [...intervalCarpetPing];
        arr.push(intervalId);
        setIntervalCarpetPing(arr);
    }

    function clearCarpetAllInterval() {
        for (let i = 0; i < intervalCarpetPing.length; i++) {
            let intId = intervalCarpetPing[i];
            clearInterval(intId);
        }

        setIntervalCarpetPing([]);
    }

    async function getStaticData() {
        const requestOption = {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}tour/GetTourData/${tourId}/${cultureName}`, requestOption);
        const response = await fetchedRes.json();
        if (response.success) {
            setTourData(response.tour);
            setTouchPoints(response.tour.touchPoints);
            setIsLoading(false);
        } else {
            setIsErrorStaticData(true);
            console.error(response.errorMsg);
        }
    }

    async function callGetNewData() {
        if (filterValues === null || carpetStatus === 'Completed') {
            getTourDetailFilters();
            getTourDetail(filterSelected);
        }

        setIsError(false);

        // conto quante chiamate sto facendo
        // ogni chiamata avviene dopo un tot di tempo, quindi in base al numero di chiamate so quanto è passato
        let callTicks = carpetTime * searchCarpetParameter.tour.carpetPingMs;
        let finishTime = callTicks > searchCarpetParameter.tour.carpetMaxTimeMs
        setCarpetTime(carpetTime + 1);

        if (carpetStatus !== 'Completed' && !finishTime) {
            setIsLoadingFilter(true);

            let interval = setInterval(() => {
                getCarpetStatus();
            }, searchCarpetParameter.tour.carpetPingMs);
            addInterval(interval);

        } else {
            setIsLoadingFilter(false);
        }
    }

    async function getTourDetail(filterSelectedUpdate) {
        setIsErrorListResult(false);
        setIsLoadingFilter(true);

        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetAvailabilityId ? carpetAvailabilityId : carpetSearchId,
            clientFlowId: clientFlowId,
            tourId: tourId,

            numOptionsToView: filterSelectedUpdate.numOptionsToView,
            showAllOptions: filterSelectedUpdate.showAllOptions,

            selectDate: filterSelectedUpdate.selectDate,
            onlyAvailable: filterSelectedUpdate.onlyAvailable,
            availRoomsReq: filterSelectedUpdate.availRoomsReq
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}tour/getTouravailability`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setTourDetail(response.tour);

            let haveResult = response.tour && response.tour.roomGroupInfo && response.tour.roomGroupInfo.length > 0;
            if (!haveResult)
                setIsErrorListResult(true);

            if (response.permissions)
                setEnableCanAddToCart(response.permissions['ADDTOCART']);

            /*            if (!filterSelected.selectDate && haveResult) {
                            // FABIO setto quello che mi arriva nell'option, la prima volta non ho nessun selectData selezionato
                            let filterSelectedUpdate = {
                                ...filterSelected,
                                selectDate: response.tour.groupedOptions[0].optionDate
                            };
            
                            setFilterSelected(filterSelectedUpdate);
                        }*/
        } else {
            setIsErrorListResult(true);
        }

        setIsLoadingListResult(false);
        setIsLoadingFilter(false);
    }

    async function getTourDetailFilters() {
        let requestParam = {
            cultureCode: cultureName,
            carpetId: carpetSearchId,
            tourId: tourId
        };

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestParam)
        };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}tour/GetTourAvailabilityFilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setFilterValues(response);

            if (!filterSelected.selectDate && response.calendar && response.calendar.availableDates && response.calendar.availableDates.length > 0) {
                let filterSelectedUpdate = {
                    ...filterSelected,
                    selectDate: response.calendar.availableDates[0]
                };

                setFilterSelected(filterSelectedUpdate);
            }
        }
    }

    // Filtri
    function onClickShowAll() {
        let filterSelectedUpdate = {
            ...filterSelected,
            showAllOptions: !filterSelected.showAllOptions
        };

        setFilterSelected(filterSelectedUpdate);
        getTourDetail(filterSelectedUpdate);
    }

    function onSelectDate(myDate) {
        setIsErrorListResult(false);
        setIsLoadingListResult(true);
        

        let filterSelectedUpdate = {
            ...filterSelected,
            selectDate: myDate
        };

        filterSelectedUpdate.availRoomsReq = [];

        setFilterSelected(filterSelectedUpdate);
        getTourDetail(filterSelectedUpdate);
    }


    function onSetMealPlanIdAndSequence(mealPlanIdAndSequence) {
        if (tourDetail && tourDetail.roomGroupInfo && tourDetail.roomGroupInfo.length === 1) {
            return;
        }

        let updFilterSelected = { ...filterSelected };
        if (mealPlanIdAndSequence) {
            if (updFilterSelected.availRoomsReq.filter(item => item.id === mealPlanIdAndSequence.id).length > 0) {
                updFilterSelected.availRoomsReq = updFilterSelected.availRoomsReq.filter((item) => item.id !== mealPlanIdAndSequence.id && item.sequence < mealPlanIdAndSequence.sequence);
            }
            else {
                updFilterSelected.availRoomsReq.push({ id: mealPlanIdAndSequence.id, sequence: mealPlanIdAndSequence.sequence });
            }
        }
        else {
            updFilterSelected.availRoomsReq = [];
        }

        setFilterSelected(updFilterSelected);
        getTourDetail(updFilterSelected, 0);
    }


    function onHandleRoomExtras(extrasRooms) {
        let _extrasRoom = [...extrasRoom];

        if (_extrasRoom.length > 0 && _extrasRoom.find(x => x.mealPlanId === extrasRooms.mealPlanId)) {
            if (extrasRooms.extras.isSelected) {
                _extrasRoom.find(x => x.mealPlanId === extrasRooms.mealPlanId).selectExtraIds.push(extrasRooms.extras.id);
            }
            else {
                _extrasRoom.find(x => x.mealPlanId === extrasRooms.mealPlanId).selectExtraIds = _extrasRoom.find(x => x.mealPlanId === extrasRooms.mealPlanId).selectExtraIds.filter(x => x !== extrasRooms.extras.id);
            }
        }
        else {
            if (extrasRooms.extras.isSelected)
                _extrasRoom.push({ mealPlanId: extrasRooms.mealPlanId, roomId: extrasRooms.roomId, selectExtraIds: [extrasRooms.extras.id] });
        }

        setExtrasRoom(_extrasRoom);
    }

    function onHandleFinalBook(finalMealPlan, totalAccomodation) {
        let bookItem = {
            cultureCode: cultureName,
            carpetId: carpetAvailabilityId,
            selectedRoomIds: [],
            selectRoomExtras: []
        };

        let canAddToCart = false;
        let selectedRoomIds = [];
        if (totalAccomodation === 1) {
            canAddToCart = true;
            selectedRoomIds = [finalMealPlan.id];
        }
        else if (filterSelected.availRoomsReq && filterSelected.availRoomsReq.length === totalAccomodation) {
            canAddToCart = true;
            selectedRoomIds = filterSelected.availRoomsReq.map(x => x.id);
        }

        bookItem.selectedRoomIds = selectedRoomIds;

        if (extrasRoom && extrasRoom.length > 0) {
            // match tra camera selezionata ed extra selezionati
            selectedRoomIds.forEach(function (selectedRoom) {
                if (extrasRoom.find(x => x.mealPlanId === selectedRoom)) {
                    let roomFound = (extrasRoom.find(x => x.mealPlanId === selectedRoom));
                    bookItem.selectRoomExtras.push({ roomId: roomFound.roomId, selectExtraIds: roomFound.selectExtraIds });
                }
            });
        }

        if (canAddToCart) {
            const addToCart = async (bookItem) => { callAddToCart(bookItem); }
            addToCart(bookItem);
        }
    }

    async function callAddToCart(bookItem) {
        bookItem.cultureCode = cultureName;
        bookItem.carpetId = carpetSearchId;
        bookItem.clientFlowId = clientFlowId;
        bookItem.selectDate = filterSelected.selectDate;
        bookItem.tourId = tourId;

        bookItem.optionCode = "";

        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(bookItem)
        };

        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}tour/addtocart`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response.success) {
                if (quotationInfo && quotationInfo.quotationId) {
                    if (quotationInfo.isFromCart)
                        window.location.href = `cart-quotation`;
                    else
                        window.location.href = `quotationTO/${quotationInfo.quotationId}`;
                } else {
                    window.location.href = `cart`;
                }
            }

        } else {
            console.log(fetchedRes);
        }

    }


    function onOpenMap() {
        window.location.href = configData.Settings.Base_Url + "tour/tourDetailMap/?tId=" + tourId + "&cfId=" + clientFlowId;
    }

    function backToList() {
        window.location.href = `/tour/tourlist/?cId=${carpetSearchId}&cfId=${clientFlowId}`;
    }

    return (
        <>
            {
                configData.Settings.Website.EngineType === 'WITHNAVBAR' && <ProductNavBar moduleSelected="TOUR" />
            }
            {!isLoading && isError && <Error textMsg={t('Error:GenericBlog')} />}
            {
                !isLoading && isErrorStaticData && <div className="card border-light  bg-gray-100 small mb-2">
                    <div className="row small ">
                        <div className="col-12">
                            <Error textMsg={t('')} />
                        </div>
                    </div>
                </div>
            }
            {
                !isLoading && <main className="bg-gray-300">

                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="cursor-pointer small mb-1" onClick={_ => backToList()}>
                                <span class="material-icons-outlined icon-small">arrow_back_ios</span> {t('ActionMenu:ReturnList')}
                            </div>
                        </div>
                    </div>
                    <div className="container mt-5 ">
                        <div className="card border-light bg-white p-1">
                            <div className="row px-2">
                                <ActivityDetailFavourite activityDetail={tourData} />
                            </div>
                            <div className="row p-3">
                                <Gallery gallery={tourData.images} alternativeText={tourData.name} productType={configData.Settings.Products.Tour.IdTipoProdotto} />
                            </div>
                            <div className="row px-1">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                                    <ActivityDetailHeader activityDetail={tourData} />

                                    <ActivityDetailDescriptions
                                        activityDetail={tourData}
                                        showOnlyGeneral="true" />
                                </div>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <TourDetailCategoryInfo
                                        tourId={tourId}
                                        tourDetail={tourData} />
                                </div>
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8">
                                    {
                                        filterValues && <ProductCalendar productCalendar={filterValues.calendar}
                                            defaultDate={filterSelected.selectDate}
                                            onSelectDate={onSelectDate}
                                            imageUrl="https://media.activitiesbank.com/38027/ENG/XL/38027_1.jpg" />
                                    }
                                    {
                                        isLoadingListResult && !isError && !isErrorListResult && <div className="card border-light  bg-gray-100 small mb-2">
                                            <div className="row small ">
                                                <div className="col-12">
                                                    <Loading textMsg={t("MyBook:Loading")} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        (!isLoadingListResult && (isErrorListResult || !tourDetail || !tourDetail.roomGroupInfo || tourDetail.roomGroupInfo.length === 0)) && <div className="card border-light  bg-gray-100 small mb-2">
                                            <div className="row small mt-3">
                                                <div className="col-12 text-center text-danger py-2">
                                                    <Error textMsg={t("Print:NothingOption")} />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {!isLoadingListResult && tourDetail && tourDetail.roomGroupInfo && tourDetail.roomGroupInfo.map((roomGroup, accomodationIdx) =>
                                        <React.Fragment key={accomodationIdx}>
                                            <div className="card border-light bg-gray-300 pt-2 mt-4">
                                                <div className="row p-1 ">
                                                    <div className="h5 col-12 col-lg-5 ">
                                                        <data m3lab="Product.structure.Accomodation">{t(`Product:Structure:Accomodation`)} <var>{roomGroup.sequence}</var></data>
                                                    </div>
                                                    <div className="h5 col-12 col-lg-2 ">
                                                        {filterValues && filterValues.tourSearchParameters && filterValues.tourSearchParameters.roomsComposition && filterValues.tourSearchParameters.roomsComposition.length > 0 &&
                                                            <M3Pax
                                                                adt={filterValues.tourSearchParameters.roomsComposition[accomodationIdx] && filterValues.tourSearchParameters.roomsComposition[accomodationIdx].adults}
                                                                chd={filterValues.tourSearchParameters.roomsComposition[accomodationIdx] && filterValues.tourSearchParameters.roomsComposition[accomodationIdx].children}
                                                                inf={filterValues.tourSearchParameters.roomsComposition[accomodationIdx] && filterValues.tourSearchParameters.roomsComposition[accomodationIdx].childAges}
                                                            />
                                                        }
                                                    </div>
                                                    <div className="col-12 col-lg-5 text-right">
                                                        {
                                                            enableShowNetPrices && <button className={(showPriceBar ? "tp-btn-select" : "tp-btn-outline-select") + " p-1 px-2 pt-2 rounded border-0 shadow"} m3rool="f_ShowPriceBar" onClick={(e) => { onChangeShowPriceBar() }}>
                                                                <data m3lab="Button.Net">
                                                                    {t("Button:Net")}
                                                                </data>
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                                {roomGroup.roomGroups && roomGroup.roomGroups.map((room, idx) =>
                                                    <TourRoom
                                                        key={idx}
                                                        room={room}
                                                        roomIndex={idx}
                                                        showPriceBar={showPriceBar}
                                                        showCost={showCost}
                                                        enableShowPriceBreakDown={enableShowPriceBreakDown}
                                                        enableCanAddToCart={enableCanAddToCart}
                                                        totalAccomodation={tourDetail.roomGroupInfo.length}
                                                        handleMealPlanIdAndSequence={onSetMealPlanIdAndSequence}
                                                        multiRoomSelected={filterSelected.availRoomsReq}
                                                        handleFinalBook={onHandleFinalBook}
                                                        quotationInfo={quotationInfo}
                                                        handleRoomExtras={onHandleRoomExtras}
                                                        supplier={supplier}
                                                    />
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}

                                    {
                                        configData.Settings.Website.ShowTourDetailTouchPoints && <TourDetailTouchPoints tourData={tourData} />
                                    }
                                    
                                    <TourDetailDescriptions tourDetail={tourData} />
                                </div>
                                <div className="col-12 col-sm-4">
                                    {
                                        filterValues && touchPoints &&
                                        <>
                                            <div className="col-12 p-1">
                                                <a className="card border-light  text-center bg-gray-400 text-blue  shadow h5 text-decoration-none cursor-pointer" onClick={(e) => { onOpenMap() }}>
                                                    <div className="p-2">
                                                        <data m3lab="Product.Structure.ViewMap"></data>{t(`Product:Structure:ViewMap`)}
                                                    </div>
                                                </a>
                                            </div>

                                            <ListSearchEngineCV
                                                imageUrl=""
                                                searchParameters={filterValues.tourSearchParameters}
                                                productType={configData.Settings.Products.Tour.IdTipoProdotto}
                                                typeSearch="AVL"
                                                extraParams={{
                                                    tourId: tourId,
                                                    carpetId: carpetSearchId,
                                                    clientFlowId: clientFlowId,
                                                    testRequest: params.get('testRequest'),
                                                    onRequest: onRequest,
                                                    productName: (tourData && tourData.name ? tourData.name : ''),
                                                    touchPoints: touchPoints,
                                                    supplier: supplier
                                                }}
                                                isShowMap={true}
                                            />
                                        </>
                                    }
                                    {
                                        !filterValues && touchPoints && <ListSearchEngineCV
                                            imageUrl=""
                                            productType={configData.Settings.Products.Tour.IdTipoProdotto}
                                            typeSearch="AVL"
                                            extraParams={{
                                                tourId: tourId,
                                                carpetId: carpetSearchId,
                                                clientFlowId: clientFlowId,
                                                testRequest: params.get('testRequest'),
                                                onRequest: onRequest,
                                                productName: (tourData && tourData.name ? tourData.name : ''),
                                                touchPoints: touchPoints,
                                                supplier: supplier
                                            }}
                                            isShowMap={true}
                                        />
                                    }

                                    <TourDetailInclusionExclusion activityDetail={tourData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </main >
            }
        </>
    );
}