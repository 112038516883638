import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import { M3Icon } from "../../Common/M3Icon";
import { M3Pax } from "../../Common/M3Pax";
import { getSearchParameterDate, getPassengerComposition, TemplateInclusion, canStartSearch } from '../TemplateUtils';
import { TransferItemList } from '../../Product/Transfer/components/TransferItemList';
import { imageOnLoadTransfer } from '../../../js/CDNUtility';
import { scrollTo, formatPrice, getWebsiteId } from '../../../js/Utils.js';
import configData from "../../../appsettings.json";

export const TransferTemplate = ({ templateData, products, selectedProducts, selectedProductData, searchParameters, isAlternative, isOptional, onSelectAlternative, onUpdateSelectedInfoData, onUnselectOptional, onFinishSearch }) => {
    const { t } = useTranslation();
    const cookies = new Cookies();
    const cultureName = cookies.get("CultureName");

    // debug
    const [searchParams, setSearchParams] = useSearchParams();
    let debug = searchParams.get("debug");

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchStarted, setSearchStarted] = useState(false);

    // src
    const [srcCarpetId, setSrcCarpetId] = useState(null);
    const [transfer, setTransfer] = useState(null);
    const [srcTransferInfos, setSrcTransferInfos] = useState(null);
    const [showTransferList, setShowTransferList] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [pageSelected, setPageSelected] = useState(0);
    // src - filters
    const [srcTransferFilters, setSrcTransferFilters] = useState(null);
    const [srcFilterSelected, setSrcFilterSelected] = useState({ rangePrice: null, transferName: null, pageNumber: 0, orderBy: 0, departureDestination: [], arrivalDestination: [], vehicleType: [], suppliers: [] });


    useEffect(() => {
        setIsLoading(true);
        onFinishSearch(templateData.templateDataId, true);

        if (searchStarted) {
            callTemplateStartSearch();
        }
    }, [templateData]);

    useEffect(() => {
        if (searchStarted || !canStartSearch(templateData.templateDataId, products, selectedProducts))
            return;

        setSearchStarted(true);
        callTemplateStartSearch();
    }, [selectedProducts]);


    // start search
    async function callTemplateStartSearch(internalId) {
        let sCarpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.SrcCarpetId : selectedProductData.AlternativeInfo.SrcCarpetId
            : srcCarpetId;

        let requestParam = {
            TemplateId: templateData.templateId,
            TemplateDataId: templateData.templateDataId,
            DepartureDate: getSearchParameterDate(searchParameters, templateData, configData.Settings.Products.Transfer.IdTipoProdotto, "CHECKIN"),
            PaxComposition: getPassengerComposition(searchParameters, configData.Settings.Products.Transfer.IdTipoProdotto),
            Citizenship: searchParameters.citizenship,
            WebsiteId: getWebsiteId(),
            SessionToken: cookies.get("SessionToken"),
            CultureCode: cultureName,
            SrcCarpetId: sCarpetId,
            InternalId: internalId
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Transfer/TemplateStartSearch`, requestOption);

        let isError = true;

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();

            if (response && response.success && response.transfer) {
                isError = false;

                setSrcCarpetId(response.transfer.srcCarpetId);
                setTransfer(response.transfer);

                let setDefaultOption = selectedProductData && (
                    (selectedProductData.SelectedInfo && !selectedProductData.SelectedInfo.ProductSelection) ||
                    (templateData.inclusion === TemplateInclusion.Optional && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.ProductSelection)
                );

                if (setDefaultOption) { // set default Transfer
                    // setto la Option di default
                    let productSelection = `${response.transfer.internalId}`;
                    let productPriceDetail = {
                        DisplayPrice: response.transfer.pricing.displayPrice,
                        NetPrice: response.transfer.pricing.networkPrice,
                        Markup: response.transfer.pricing.agencyMarkupVal,
                        Fee: response.transfer.pricing.agencyFee,
                        Commission: response.transfer.pricing.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.transfer.srcCarpetId, response.transfer.srcCarpetId, productSelection, productPriceDetail, true, false);
                }

                // setto DefaultAlternativePrice
                let setDefaultAlternativePrice = selectedProductData && selectedProductData.AlternativeInfo && !selectedProductData.AlternativeInfo.SelectedTotalPrice;
                if (setDefaultAlternativePrice) {
                    let productSelection = `${response.transfer.internalId}`;
                    let productPriceDetail = {
                        DisplayPrice: response.transfer.pricing.displayPrice,
                        NetPrice: response.transfer.pricing.displayNet,
                        Markup: response.transfer.pricing.agencyMarkupVal,
                        Fee: response.transfer.pricing.agencyFee,
                        Commission: response.transfer.pricing.commission
                    };
                    onUpdateSelectedInfoData(templateData.templateDataId, response.transfer.srcCarpetId, response.transfer.srcCarpetId, productSelection, productPriceDetail, true, false);
                }
            }
        }

        setIsError(isError);
        if (isError) {
            onUpdateSelectedInfoData(templateData.templateDataId, null, null, null, null, true, true);
        }

        setIsLoading(false);
        onFinishSearch(templateData.templateDataId, false);
    }

    // src functions
    async function getTransferList(e, pageNumber, filters) {
        if (e) e.preventDefault();

        let sCarpetId = selectedProductData
            ? !isAlternative && !isOptional ? selectedProductData.SelectedInfo.SrcCarpetId : selectedProductData.AlternativeInfo.SrcCarpetId
            : srcCarpetId;

        if (!filters) {
            filters = srcFilterSelected;
        }

        let requestParam = {
            cultureCode: cultureName,
            carpetId: sCarpetId,
            pageNumber: pageNumber ?? 0,
            pageSize: 10,
            transferName: filters.transferName,
            departureDestination: filters.departureDestination,
            arrivalDestination: filters.arrivalDestination,
            vehicleType: filters.vehicleType,
            suppliers: filters.suppliers,
            rangePrice: filters.rangePrice,
            orderBy: 0,
            showCost: false
        };

        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Transfer/GetTransferList`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setSrcTransferInfos(response);
            renderPaginator(response.totalTransfers);

            await getTransferListFilters(sCarpetId);

            setShowTransferList(true);
        }
    }
    async function getTransferListFilters(carpetId) {
        let requestParam = { cultureCode: cultureName, carpetId: carpetId };
        const requestOption = { method: 'POST', credentials: 'include', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(requestParam) };
        const fetchedRes = await fetch(`${configData.Settings.CommonApi_BaseUrl}Transfer/GetTransferListFilters`, requestOption);

        if (fetchedRes.ok) {
            const response = await fetchedRes.json();
            setSrcTransferFilters(response);
        }
    }
    function renderPaginator(totalItems) {
        setPageCount(Math.ceil(totalItems / pageSize));
    }
    const handlePageClick = async e => {
        scrollTo(null, `carList_${templateData.templateDataId}`);
        setPageSelected(e.selected);
        getTransferList(null, e.selected);
    };
    async function onSelectTransfer(internalId) {
        callTemplateStartSearch(internalId);

        scrollTo(null, `transferList_${templateData.templateDataId}`);
        setShowTransferList(false);
    }

    // buttons
    const onButtonSelect = () => {
        // funzione usata quando si seleziona un prodotto opzionale o in alternativa, quindi viene selezionata la option con prezzo piu basso
        let productSelection = `${transfer.internalId}`;
        let productPriceDetail = {
            DisplayPrice: transfer.pricing.displayPrice,
            NetPrice: transfer.pricing.networkPrice,
            Markup: transfer.pricing.agencyMarkupVal,
            Fee: transfer.pricing.agencyFee,
            Commission: transfer.pricing.commission
        };
        onSelectAlternative(templateData.templateDataId, selectedProductData.SelectedId, productSelection, productPriceDetail, transfer.srcCarpetId, transfer.srcCarpetId);
    }
    const onButtonUnselect = () => {
        onUnselectOptional(templateData.templateDataId);
    }

    // pricing
    const getTotalPriceDiff = (transfer) => {
        // funzione usata per calcolare la differenza di prezzo tra la option scelta e la best del prodotto opzionale/in alternativa
        let totalPrice = transfer.pricing.displayPrice;

        if (!selectedProductData || !selectedProductData.SelectedTotalPrice) {
            if (isOptional) {
                let formattedTotalPrice = formatPrice(totalPrice, transfer.pricing.valutaCliente, cultureName);
                return `+${formattedTotalPrice}`;
            }

            return (<></>);
        }

        let diff = totalPrice - selectedProductData.SelectedTotalPrice.DisplayPrice;
        if (diff === 0)
            return "+ " + formatPrice(diff, transfer.pricing.valutaCliente, cultureName);

        let formattedPrice = formatPrice(diff, transfer.pricing.valutaCliente, cultureName);

        return `${diff > 0 ? "+" : ""}${formattedPrice}`;
    }

    // render
    const renderSelectedProduct = () => {
        if (isError)
            return (<h6 className="red">Prodotto non disponibile.</h6>);

        if (!transfer)
            return (<></>);

        if ((!isAlternative && !isOptional) || isOptional) {
            return (
                <React.Fragment>
                    <TransferItemList
                        item={transfer}
                        showPriceBar={false}
                        showCost={false}
                        templateInfo={{
                            templateId: templateData.templateId,
                            selectedProductPrice: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductPriceDetail) ? selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice : null,
                            selectedProductCode: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductSelection) ? selectedProductData.SelectedInfo.ProductSelection : null,
                            showSelectButton: false,
                            isPrimary: true
                        }}
                    />
                </React.Fragment>
            );
        } else {
            return (<></>);
        }
    }


    return (
        <>
            {templateData &&
                <React.Fragment>
                    {debug &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <p style={{ width: '500px' }}>
                                {selectedProductData && JSON.stringify(selectedProductData)}
                            </p>
                        </div>
                    }

                    {!isAlternative && !isOptional &&
                        <React.Fragment>
                            {isLoading && <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                                <div className="rounded" style={{ backgroundImage: "url(" + (templateData.product.thumbUrl ? encodeURI(templateData.product.thumbUrl) : imageOnLoadTransfer()) + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover", height: "100%" }}>
                                </div>
                            </div>}

                            <div className={isLoading ? "col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9" : "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                                <div className="myBook-card-body">
                                    {isLoading
                                        ? (
                                            <div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                                        <span><data m3lab="Product.Transfer.Item">{t(`Product:Transfer:Item`)}</data></span>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 px-4">
                                                        <M3Pax adt={searchParameters.totalAdults} chd={searchParameters.totalChildren} inf={searchParameters.totalInfants} />
                                                    </div>
                                                </div>
                                                <div className="h5">
                                                    <var>{templateData.product.name}</var>
                                                </div>
                                                <div>
                                                    <div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div> {t(`Template:Loading`)}
                                                </div>
                                            </div>
                                        )
                                        : (
                                            <>
                                                {/* Menu Functions */}
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="btn-group text-right float-end pr-2">
                                                            <button id={templateData.templateDataId} className="btn p-0 m-0" type="button" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                                                <data m3ico="MenuFunctions">
                                                                    <M3Icon iconName="MenuFunctions" />
                                                                </data>
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="menuItem1">
                                                                {transfer &&
                                                                    <li>
                                                                        <a id={`aShowTransferList_${templateData.templateDataId}`} href className="dropdown-item" onClick={e => getTransferList(e)}>
                                                                            <data m3ico="Transfer icon-10"><M3Icon iconName="Transfer" externalClass="icon-10 small text-gray-600" /></data>
                                                                            <label><data m3lab="ActionMenu.TransferChange">{t(`ActionMenu:TransferChange`)}</data></label>
                                                                        </a>
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        {renderSelectedProduct()}
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>

                            {/* Button Remove when Optional */}
                            {!isLoading && templateData.inclusion === TemplateInclusion.Optional && (
                                <>
                                    <div>
                                        <div className="float-end small text-blue">
                                            <button id={`btnUnselect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonUnselect()}>
                                                <span className="text-danger">
                                                    <data m3lab="Template.Delete">Rimuovi</data>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </>
                            )}
                        </React.Fragment>
                    }

                    {(isAlternative || isOptional) && !isLoading && !isError &&
                        <React.Fragment>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="row rounded rounded-3 bg-gray-300 my-1 mx-0 p-1">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                        <div className="w-100">
                                            <div className="h6 m-0">
                                                <var>{t(`Product:Transfer:Item`)} {templateData.product.name}</var>
                                            </div>

                                            {!isLoading && renderSelectedProduct()}
                                        </div>
                                    </div>

                                    {!isError &&
                                        <div className="col-3 col-sm-4 col-md-2 col-lg-2 col-xl-2 text-right float-end pr-0 align-middle h-100">
                                            <button id={`btnSelect_${templateData.templateDataId}`} className="btn btn-sm tp-btn-outline-search py-0" onClick={e => onButtonSelect()}>
                                                {isLoading && <><div className="spinner-border spinner-border-sm text-primary" role="status" style={{ 'fontSize': '12px' }}></div>{t(`Template:LoadingAlternatives`)}</>}

                                                {!isLoading && transfer &&
                                                    <React.Fragment>
                                                        <data m3lab="Template.Option">{isAlternative ? t(`Template:Option`) : t(`Template:Add`)}</data>
                                                        <div>
                                                            {getTotalPriceDiff(transfer)}
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </React.Fragment>
                    }

                    {/* Transfer List */}
                    {srcTransferInfos && srcTransferInfos.transfers && srcTransferInfos.transfers.length > 0 && showTransferList &&
                        <React.Fragment>
                            {/* Filters */}


                            {/* Search List */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                {/* Items */}
                                <a id={`transferList_${templateData.templateDataId}`} href></a>

                                {srcTransferInfos.transfers.map((item, key) => {
                                    return <TransferItemList key={key}
                                        item={item}
                                        showPriceBar={false}
                                        templateInfo={{
                                            templateId: templateData.templateId,
                                            selectedProductPrice: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductPriceDetail) ? selectedProductData.SelectedInfo.ProductPriceDetail.DisplayPrice : null,
                                            selectedProductCode: (selectedProductData && selectedProductData.SelectedInfo && selectedProductData.SelectedInfo.ProductSelection) ? selectedProductData.SelectedInfo.ProductSelection : null,
                                            showSelectButton: true,
                                            isPrimary: false
                                        }}
                                        modalOpenProductId={null}
                                        onClickSearch={onSelectTransfer}
                                    />
                                })}

                                { /* Paginator */}
                                {srcTransferInfos && srcTransferInfos.transfers && srcTransferInfos.transfers.length > 0 && showTransferList &&
                                    <>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-1">
                                            <nav aria-label="Page navigation comments" className="mt-4">
                                                <ReactPaginate
                                                    breakLabel="..."
                                                    nextLabel={t('pagination:nextLabel')}
                                                    onPageChange={handlePageClick}
                                                    pageRangeDisplayed={5}
                                                    pageCount={pageCount}
                                                    previousLabel={t('pagination:previousLabel')}
                                                    renderOnZeroPageCount={null}
                                                    forcePage={pageSelected}
                                                    breakClassName="page-item"
                                                    breakLinkClassName="page-link"
                                                    marginPagesDisplayed={2}
                                                    containerClassName="pagination justify-content-center"
                                                    pageClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    previousClassName="page-item"
                                                    previousLinkClassName="page-link"
                                                    nextClassName="page-item"
                                                    nextLinkClassName="page-link"
                                                    activeClassName="active"
                                                />
                                            </nav>
                                        </div>
                                    </>
                                }
                            </div>
                        </React.Fragment>
                    }

                </React.Fragment>
            }
        </>
    );
}
