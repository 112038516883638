import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { DebounceInput } from 'react-debounce-input';
import { M3Icon } from "../M3Icon";
import configData from "../../../appsettings.json";
import { handleError, getWebsiteId } from "../../../js/Utils";

export const InputSearchDestination = ({ defaultSelectDestination, productType, callOnSearchDestination, modeView, closePanelDestination, autoFocus, extraOptions, isMobile }) => {
    const cookies = new Cookies();
    const { t } = useTranslation();
    const cultureName = cookies.get("CultureName");

    let numberOfCall = 0;
    const webDestinationTypeId = {
        Continent: 1,
        Country: 2,
        State: 3,
        City: 4,
        CityLocation: 5,
        Zone: 6,
        ZoneGroup: 7,
        Destination: 8,
        Airport: 9,
        CityIATA: 10,
        CruiseArea: 11,
        PointOfInterest: 12,
        MetroStation: 13,
        TrainStation: 14,
        Port: 15
    };

    const [iconWidth, setIconWidth] = useState('w-10');
    const [inputWidth, setInputWidth] = useState('w-70');

    const [inputValue, setInputValue] = useState('');
    const updateValue = (newValue) => {
        if (newValue.length < 3)
            return;

        callOnSearchDestination({ isLoading: true, isError: false, options: [], searchText: newValue, providers: '' });
        setInputValue(newValue);

        let searchConfig = {
            Text: newValue,
            DestinationTypes: [],
            DestinationProviders: [],
            //DestinationFilter: null,
            CultureCode: cultureName,
            //websiteId: getWebsiteId(),
            //webDestrinationTreeId: 1,
            // PickUpDropoff: 1
        };

        let searchConfigV2 = {
            text: newValue,
            language: cultureName,
            numberOfResults: 100,
            //searchType default equal
            types: [],
            inclusions: ["DEFAULT"], // tag in config
            exlusions: []
        };

        if (configData.Settings.MongoDB && configData.Settings.MongoDB.Inclusions && configData.Settings.MongoDB.Inclusions.length > 0)
            searchConfigV2.inclusions = configData.Settings.MongoDB.Inclusions;

        if (configData.Settings.MongoDB && configData.Settings.MongoDB.Exclusions && configData.Settings.MongoDB.Exclusions.length > 0)
            searchConfigV2.exlusions = configData.Settings.MongoDB.Exclusions;

        let destinationTypes = [];
        let providers = [];
        let searchRules = [];

        let rules = extraOptions ? extraOptions.rules : null;

        switch (productType) {

            case configData.Settings.Products.Activity.IdTipoProdotto: {
                searchConfig.WebDestrinationTreeId = configData.Settings.Products.Activity.WebDestinationTreeId;

                providers.push("MongoDB");

                if (!rules || rules.enableDestination)
                    destinationTypes.push(webDestinationTypeId.City);

                if (!rules || rules.enableZone)
                    destinationTypes.push(webDestinationTypeId.Zone);

                searchRules.push({
                    providers: distinctItem(providers),
                    destinationTypes: distinctItem(destinationTypes)
                });

                break;
            }

            case configData.Settings.Products.CarRental.IdTipoProdotto: {
                searchConfig.WebDestrinationTreeId = configData.Settings.Products.CarRental.WebDestinationTreeId;

                if (!rules || rules.enableDestination) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.Destination);
                    destinationTypes.push(webDestinationTypeId.City);
                }

                if (!rules || rules.enableIata) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.Airport);
                    destinationTypes.push(webDestinationTypeId.City);
                }

                if (!rules || rules.enablePOI) {
                    destinationTypes.push(webDestinationTypeId.PointOfInterest);

                    if (configData.Settings.Google && configData.Settings.Google.ApiKeyServer)
                        providers.push("Google");
                    else
                        providers.push("OpenStreetMap");

                    providers.push("LocationIq");

                    destinationTypes.push(4); // POI
                }

                searchRules.push({
                    providers: distinctItem(providers),
                    destinationTypes: distinctItem(destinationTypes)
                });

                break;
            }

            case configData.Settings.Products.Flight.IdTipoProdotto: {
                searchConfig.WebDestrinationTreeId = configData.Settings.Products.Flight.WebDestinationTreeId;

                providers.push("MongoDB");

                if (!rules || rules.enableAirport) {
                    destinationTypes.push(webDestinationTypeId.Airport);
                }

                if (!rules || rules.enableCity) {
                    destinationTypes.push(webDestinationTypeId.CityIATA);
                }

                searchRules.push({
                    providers: distinctItem(providers),
                    destinationTypes: distinctItem(destinationTypes)
                });

                break;
            }

            case configData.Settings.Products.Structure.IdTipoProdotto: {
                searchConfig.WebDestrinationTreeId = configData.Settings.Products.Structure.WebDestinationTreeId;

                if (!rules || rules.enableDestination) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.City);
                }

                if (!rules || rules.enableZone) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.Zone);
                }

                if (!rules || rules.enableZoneGroup) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.ZoneGroup);
                }

                if (!rules || rules.enableIata) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.Airport);
                    destinationTypes.push(webDestinationTypeId.City);
                }

                if (!rules || rules.enableCity) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.City);
                }

                searchRules.push({
                    providers: distinctItem(providers),
                    destinationTypes: distinctItem(destinationTypes)
                });

                providers = [];
                destinationTypes = [];
                if (!rules || rules.enablePOI || rules.enableStructure) {
                    destinationTypes.push(webDestinationTypeId.PointOfInterest);

                    if (configData.Settings.Google && configData.Settings.Google.ApiKeyServer)
                        providers.push("Google");
                    else
                        providers.push("OpenStreetMap");

                    providers.push("LocationIq");

                    destinationTypes.push(4); // POI
                }

                if (providers.length > 0)
                    searchRules.push({
                        providers: distinctItem(providers),
                        destinationTypes: distinctItem(destinationTypes)
                    });

                break;
            }

            case configData.Settings.Products.Transfer.IdTipoProdotto: {
                searchConfig.WebDestrinationTreeId = configData.Settings.Products.Transfer.WebDestinationTreeId;

                if (rules && rules.enableDestination) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.City);
                }

                if (rules && rules.enableIata) {
                    providers.push("MongoDB");

                    destinationTypes.push(webDestinationTypeId.Airport);
                }

                searchRules.push({
                    providers: distinctItem(providers),
                    destinationTypes: distinctItem(destinationTypes)
                });

                break;
            }

            case configData.Settings.Products.Train.IdTipoProdotto: {
                searchConfig.WebDestrinationTreeId = configData.Settings.Products.Train.WebDestinationTreeId;

                providers.push("TravelPlace");
                destinationTypes.push("TrainStation");
                searchRules.push({
                    providers: distinctItem(providers),
                    destinationTypes: distinctItem(destinationTypes)
                });
                break;
            }

            case configData.Settings.Products.Cruise.IdTipoProdotto: {
                searchConfig.WebDestrinationTreeId = configData.Settings.Products.Cruise.WebDestinationTreeId;

                providers.push("MongoDB");
                destinationTypes.push(webDestinationTypeId.Port);
                searchRules.push({
                    providers: distinctItem(providers),
                    destinationTypes: distinctItem(destinationTypes)
                });

                break;
            }

            default:
                break;
        }

        searchConfig.DestinationTypes = destinationTypes;
        searchConfig.WebDestinationTypeId = destinationTypes;
        searchConfig.NewDestinationTree = true;
        if (productType === configData.Settings.Products.Train.IdTipoProdotto) {
            searchConfig.WebDestinationTypeId = [webDestinationTypeId.TrainStation];
            searchConfig.NewDestinationTree = false;
        }

        numberOfCall = searchRules.map(x => x.providers.length).reduce((a, b) => a + b);
        for (let i = 0; i < searchRules.length; i++) {
            let sr = searchRules[i];

            let providers = sr.providers;
            providers.forEach((prov) => {

                if (prov === 'MongoDB') {
                    let updSCV2 = { ...searchConfigV2 };
                    updSCV2.types = sr.destinationTypes;

                    callSearchDestinationV2(updSCV2);
                } else {
                    let updSC = { ...searchConfig };
                    updSC.DestinationProviders = [prov];
                    if (productType !== configData.Settings.Products.Train.IdTipoProdotto) {
                        updSC.WebDestinationTypeId = sr.destinationTypes;
                    }

                    callSearchDestination(updSC);
                }

            });
        }

    };

    function callSearchDestinationV2(updSC) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updSC)
        };

        fetch(`${configData.Settings.CommonAPI_Search}V2`, requestOption)
            .then((res) => handleError(res))
            .then((items) => {
                let mappingItems = [];

                if (productType === configData.Settings.Products.Flight.IdTipoProdotto) {
                    // In caso siano voli e ci sono città, come risultato ci arriva {Text: 'Milano', Type:'City', Children: [elenco aereoporti]}
                    for (let i = 0; i < items.length; i++) {
                        let father = items[i];

                        let myItem = createMappingItem(father, true, false);
                        mappingItems.push(myItem);

                        let isCity = false;
                        if (father.Type.toUpperCase() === 'CITY')
                            isCity = true
                        else if (father.TypeId) {
                            isCity = father.TypeId === webDestinationTypeId.CityIATA;
                        }

                        if (isCity && father.Children) {
                            father.Children.forEach((x) => {
                                let myItem2 = createMappingItem(x, true, true);
                                mappingItems.push(myItem2);
                            });
                        }

                    }

                } else {

                    items.forEach((x) => {

                        // verifico quanti elementi di un tipo ho presente.
                        // il risultato dovrà contenere 20 destinazioni, e 10 per gli altri tipi
                        let countType = 0;
                        let maxItem = 10;
                        if (mappingItems != null && mappingItems.length > 0) {
                            if (x.Type === 'Destination')
                                maxItem = 20;

                            countType = mappingItems.filter(item => x.Type === item.Type).length;
                        }

                        if (countType <= maxItem) {
                            let myItem = createMappingItem(x, false, false);
                            mappingItems.push(myItem);
                        }

                    });

                }

                //mappingItems.sort(function (a, b) {
                //    return a.Priority - b.Priority;
                //});

                numberOfCall--;
                callOnSearchDestination({
                    isLoading: (numberOfCall > 0 && (!mappingItems || mappingItems.length === 0)),
                    isError: false,
                    options: mappingItems,
                    searchText: updSC.text,
                    providers: "MongoDB"
                });
            })
            .catch((err) => {
                console.error(err);
                numberOfCall--;
                callOnSearchDestination({
                    isLoading: numberOfCall > 0,
                    isError: true,
                    options: null,
                    searchText: updSC.text,
                    providers: "MongoDB"
                });
            });
    }

    function createMappingItem(destItem, isFlight, isChild) {

        let subtitle = destItem.TextFull;
        try {

            let normSubtitle = '';
            let textParts = subtitle.split(',');
            for (let i = 1; i < textParts.length; i++) {
                normSubtitle += textParts[i] + ', ';
            }

            subtitle = normSubtitle.slice(0, -2);

        } catch (es) { }

        let myItem = {
            Id: destItem.WebDestinationId,
            IataCode: destItem.IataCode,
            Latitude: destItem.Latitude !== null && destItem.Latitude !== undefined ? destItem.Latitude : 0,
            Longitude: destItem.Longitude !== null && destItem.Longitude !== undefined ? destItem.Longitude : 0,
            Type: destItem.Type,
            Provider: "MongoDB",
            Priority: destItem.Counter,
            WebDestinationTypeId: [destItem.TypeId],
            Text: destItem.TextFull,
            Title: destItem.Text,
            Subtitle: subtitle,
            IsChild: isChild,
            IsGroupTitle: false,
            GeoId: destItem.GeoId
        };

        if (isFlight) {
            myItem.Text = formatFlightTextV2(destItem);
            myItem.Title = formatFlightTextV2(destItem);
            myItem.Subtitle = '';
            myItem.IsGroupTitle = !isChild && (destItem.Type.toUpperCase() === 'CITY' || (destItem.Children && destItem.Children.length > 0));
        }

        return myItem;
    }

    function formatFlightTextV2(item) {
        let mainText;

        if (item.TypeId && item.TypeId === webDestinationTypeId.CityIATA) {
            let cityName = item.Text.split(/\((.*)/s)[0].trim();
            let cityIATACode = item.IataCode;

            mainText = `${cityName} (${cityIATACode})`;
        } else {
            mainText = getAirportMainText(item.Text);
            let secondaryText = getAirportSecondaryText(item.Text);

            mainText = `${mainText} ${secondaryText}`;
        }

        return mainText;
    }

    function callSearchDestination(updSC) {
        const requestOption = {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updSC)
        };

        fetch(`${configData.Settings.CommonAPI_Search}`, requestOption)
            .then((res) => handleError(res))
            .then((items) => {
                let mappingItems = [];

                if (productType === configData.Settings.Products.Flight.IdTipoProdotto) {
                    // In caso siano voli e ci sono città, come risultato ci arriva {Text: 'Milano', Type:'City', Children: [elenco aereoporti]}
                    for (let i = 0; i < items.length; i++) {
                        let father = items[i];

                        mappingItems.push({
                            Id: father.Id,
                            IataCode: father.IataCode,
                            Latitude: father.Latitude !== null && father.Latitude !== undefined ? father.Latitude : 0,
                            Longitude: father.Longitude !== null && father.Longitude !== undefined ? father.Longitude : 0,
                            Text: formatFlightText(father),
                            Type: father.Type,
                            Title: formatFlightText(father),
                            Provider: father.provider,
                            IsGroupTitle: father.Type.toUpperCase() === 'CITY' || (father.Children && father.Children.length > 0),
                            Priority: father.Posizione,
                            IsChild: false,
                            WebDestinationTypeId: father.WebDestinationTypeId
                        });

                        let isCity = false;
                        if (father.Type.toUpperCase() === 'CITY')
                            isCity = true
                        else if (father.WebDestinationTypeId && father.WebDestinationTypeId.length > 0) {
                            isCity = father.WebDestinationTypeId.some(x => x === webDestinationTypeId.CityIATA);
                        }

                        if (isCity && father.Children) {
                            father.Children.forEach((x) => {
                                mappingItems.push({
                                    Id: x.Id,
                                    IataCode: x.IataCode,
                                    Latitude: x.Latitude && x.Latitude !== undefined ? x.Latitude : 0,
                                    Longitude: x.Longitude && x.Longitude !== undefined ? x.Longitude : 0,
                                    Text: formatFlightText(x),
                                    GeoId: x.GeoId,
                                    Title: formatFlightText(x),
                                    Type: x.Type,
                                    Provider: father.provider,
                                    IsGroupTitle: false,
                                    Priority: father.Posizione,
                                    IsChild: true,
                                    WebDestinationTypeId: x.WebDestinationTypeId
                                });
                            });
                        }

                    }

                } else {

                    items.forEach((x) => {
                        let title = x.Text;
                        let subtitle = '';

                        try {
                            let textParts = x.Text.split(',');
                            title = textParts[0];
                            for (let i = 1; i < textParts.length; i++) {
                                subtitle += textParts[i] + ', ';
                            }

                            subtitle = subtitle.slice(0, -2);

                        } catch (es) { }

                        // verifico quanti elementi di un tipo ho presente.
                        // il risultato dovrà contenere 20 destinazioni, e 10 per gli altri tipi
                        let countType = 0;
                        let maxItem = 10;
                        if (mappingItems != null && mappingItems.length > 0) {
                            if (x.Type === 'Destination')
                                maxItem = 20;

                            countType = mappingItems.filter(item => x.Type === item.Type).length;

                        }

                        if (countType <= maxItem) {
                            mappingItems.push({
                                Id: x.Id,
                                IataCode: x.IataCode,
                                Latitude: x.Latitude && x.Latitude !== undefined ? x.Latitude : 0,
                                Longitude: x.Longitude && x.Longitude !== undefined ? x.Longitude : 0,
                                Text: x.Text,
                                GeoId: x.GeoId,
                                Title: title,
                                Subtitle: subtitle,
                                Type: x.Type,
                                Provider: x.provider,
                                IsGroupTitle: false,
                                Priority: x.Posizione,
                                isChild: x.isChild,
                                WebDestinationTypeId: x.WebDestinationTypeId
                            });
                        }

                    });

                }

                //mappingItems.sort(function (a, b) {
                //    return a.Priority - b.Priority;
                //});

                numberOfCall--;
                callOnSearchDestination({
                    isLoading: (numberOfCall > 0 && (!mappingItems || mappingItems.length === 0)),
                    isError: false,
                    options: mappingItems,
                    searchText: updSC.Text,
                    providers: updSC.DestinationProviders
                });
            })
            .catch((err) => {
                console.error(err);
                numberOfCall--;
                callOnSearchDestination({
                    isLoading: numberOfCall > 0,
                    isError: true,
                    options: null,
                    searchText: updSC.Text,
                    providers: updSC.DestinationProviders
                });
            });
    }

    function formatFlightText(item) {
        let mainText;

        if (item.WebDestinationTypeId && item.WebDestinationTypeId.includes(webDestinationTypeId.CityIATA)) {
            let cityName = item.Text.split(/\((.*)/s)[0].trim();
            let cityIATACode = item.Text.match(/\(([^()]+)\)/g);

            mainText = `${cityName} ${cityIATACode}`;
        } else {
            mainText = getAirportMainText(item.Text);
            let secondaryText = getAirportSecondaryText(item.Text);

            mainText = `${mainText} ${secondaryText}`;
        }

        return mainText;
    }

    function getAirportMainText(text) {
        let mainText = text;

        // parsing new destination name
        //var cityName = text.split(/\((.*)/s)[0].trim();
        let airportDesc = text.match(/\(([^()]+)\)/g);
        if (airportDesc && airportDesc.length > 0) {
            let airportIATACode = airportDesc[0].split(/\-(.*)/s)[0].trim() + ")";

            let arrTmp = airportDesc[0].replaceAll("(", "").replaceAll(")", "").split("-");
            let airportName = text.split(/\((.*)/s)[0].trim();
            if (arrTmp.length > 1)
                airportName = arrTmp[1]
            mainText = `${airportName} ${airportIATACode}`;
        }

        return mainText;
    }

    function getAirportSecondaryText(text) {
        let cityName = text.split(/\((.*)/s)[0].trim();
        return cityName;
    }

    function distinctItem(items) {
        return [...new Set(items.map(item => item))];
    }

    function onClosePanel() {
        closePanelDestination()
    }

    useEffect(() => {
        if (productType === configData.Settings.Products.Flight.IdTipoProdotto) {
            setIconWidth('w-20');
            setInputWidth('w-70');
        }

    }, [productType]);

    useEffect(() => {
        if (defaultSelectDestination)
            setInputValue(defaultSelectDestination.Text ? defaultSelectDestination.Text : "");

    }, [defaultSelectDestination]);

    useEffect(() => {

        document.getElementById('DestinationDI').focus();
        document.getElementById('DestinationDI').select();

    }, [autoFocus]);

    const getPlaceholder = () => {
        if (extraOptions && extraOptions.inputText)
            return t(extraOptions.inputText);

        switch (productType) {
            case configData.Settings.Products.Flight.IdTipoProdotto:
                return t('SearchEngine:DepartureAirport');

            case configData.Settings.Products.Train.IdTipoProdotto:
                return t('SearchEngine:Station');

            default:
                return t('SearchEngine:Destination');
        }
    }

    return (<>
        <div className="input-group bg-white rounded pr-1">
            <span className={"input-group-text px-2 " + (modeView === 'MODAL' ? "w-10" : "")} id="basic-addon1">
                {extraOptions && extraOptions.iconName && <M3Icon iconName={extraOptions.iconName} />}
                {(!extraOptions || !extraOptions.iconName) && <M3Icon iconName="Place" />}
            </span>
            <div className={"pl-1 pt-2 " + (modeView === 'MODAL' ? "w-75" : inputWidth)}>
                <DebounceInput
                    id="DestinationDI"
                    className="form-control border-0 shadow-none w-100"
                    minLength={3}
                    debounceTimeout={1000}
                    placeholder={getPlaceholder()}
                    value={inputValue}
                    onChange={event => updateValue(event.target.value)} />
            </div>
            {
                !isMobile && <button type="button" className="btn-close mt-2 mr-1 m-auto" onClick={_ => onClosePanel()}></button>
            }
        </div>
    </>
    );
}